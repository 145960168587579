<template>
  <div class="not-found-view">
    <h2>{{ $t('maintenance_message') }}</h2>
  </div>
</template>

<script>
export default {
  mounted(){
    setTimeout(()=>window.location.href = 'https://www.douglas.de/de/c/parfum/01',5000)
  }
}
</script>

<style lang="stylus" scoped>
.not-found-view
  position absolute
  top 50%
  left 50%
  transform translate(-50%,-50%)
  text-align center
  color #bbb
  .link
    text-decoration underline
</style>